import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { terminaMixin, bodyMixin, kozukaMixin, TitleMixin } from "../style";
import phantPhoto from "../images/img_phant.png";
import hadalyPhoto from "../images/img_hadaly.png";
import nextButton from "../images/btn_right.svg";

const Container = styled.section`
  grid-column: 3;
  grid-row: 5;

  height: 838px;
  margin-top: 52px;
  margin-bottom: 70px;
  position: relative;

  @media (max-width: 575.98px) {
    margin-top: 43px;
    margin-bottom: 38px;
    height: initial;
  }
`;

const Title = styled.h1`
  ${TitleMixin};
  @media (max-width: 575.98px) {
    margin-bottom: 16px;
  }
`;

const Profile = styled.div`
  width: 1152px;
  height: 838px;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: -1;

  &.active {
    display: block;
  }

  @media (max-width: 575.98px) {
    display: block;
    width: initial;
    height: initial;
    position: relative;
    margin-bottom: 160px;
  }

  .name {
    ${terminaMixin};
    font-size: 39.63px;
    position: absolute;
    top: 339px;
    left: 0;

    @media (max-width: 575.98px) {
      font-size: 13.21px;
      position: initial;
      padding-top: 76.666667px;
      margin 0;
    }
  }

  .jaName {
    ${kozukaMixin};
    font-size: 18px;
    position: absolute;
    top: 406px;
    left: 0;

    @media (max-width: 575.98px) {
      font-size: 7.33px;
      position: initial;
      margin-top: 0px;
    }
  }

  .desc {
    ${bodyMixin};
    font-size: 15px;
    position: absolute;
    top: 479px;
    left: 0;
    width: 440px;

    @media (max-width: 575.98px) {
      font-size: 7.33px;
      position: initial;
      margin-top: 12px;
      width: 128.120333px;
      top:initial;
      left:initial;
    }
  }

  .photo {
    position: absolute;
    top: 0;
    right: 0;
    width: 700px;

    img {
      width: 100%;
    }

    @media (max-width: 575.98px) {
      top: 0;
      width: 255px;
      z-index: -1;
    }
  }

  :nth-child(odd) {
    .photo {
      right: -24px;
    }
  }

  :nth-child(even) {
    .name {
      text-align: right;
    }
    .jaName {
      text-align: right;
    }
    .desc {
      margin-left: auto;
    }
    .photo {
      @media (max-width: 575.98px) {
        left: -24px;
      }
    }
  }
`;

const Description = styled.p``;

const Menu = styled.ul`
  margin-top: 150px;
  list-style: none;
  padding: 0;

  li {
    display: inline-box;
    & + li {
      ::before {
        display: inline-box;
        content: "/";
        margin: 0 12px;
      }
    }

    a {
      cursor: pointer;
      color: rgb(77, 77, 77);
    }

    &.active {
      a {
        cursor: default;
        color: inherit;
      }
    }
  }

  @media (max-width: 575.98px) {
    display: none;
  }
`;

const NextButton = styled.div`
  width: 59px;
  height: 115px;
  background-image: url(${nextButton});
  position: absolute;
  top: 392px;
  right: 0;
  cursor: pointer;
  @media (max-width: 575.98px) {
    display: none;
  }
`;

const characters = [
  {
    name: "PHANT",
    jaName: "ファント",
    description:
      "大西洋の海中施設ラダーの奥で発見された少女。昏睡状態のまま眠らされており、出で立ちや目的などの一切が不明である。",
    photo: phantPhoto
  },
  {
    name: "HADALY",
    jaName: "ハダリー",
    description:
      "研究員のほとんどが行方不明になってしまった海中研究施設ラダーでの唯一の生き残りの人間。今回の行方不明事件の情報をラダーを所有する会社「アルカナ」に送信した。",
    photo: hadalyPhoto
  }
];

const Characters: React.FC = () => {
  const [current, setCurrent] = useState(0);

  return (
    <Container id="character">
      <Title>Character</Title>
      <Menu>
        {characters.map((x, i) => (
          <li className={`${i === current ? "active" : ""}`}>
            <a onClick={() => setCurrent(i)}>{x.name}</a>
          </li>
        ))}
      </Menu>
      {characters.map((x, i) => {
        return (
          <Profile
            className={`character_${i} ${i === current ? "active" : ""}`}
          >
            <h2 className="name">{x.name}</h2>
            <h2 className="jaName">{x.jaName}</h2>
            <p className="desc">{x.description}</p>
            <div className="photo">
              <img src={x.photo} />
            </div>
          </Profile>
        );
      })}
      <NextButton onClick={() => setCurrent((current + 1) % 2)} />
    </Container>
  );
};

export default Characters;
