import React from "react";

const SoundCloud: React.FC = () => {
  const options = {
    url: "https://api.soundcloud.com/tracks/730692580",
    color: "000000",
    theme_color: "ffffff",
    auto_play: false,
    hide_releated: false,
    show_comments: false,
    show_user: false,
    show_reposts: false,
    show_artwork: false
  };
  const optionStr = Object.entries(options)
    .map(x => `${x[0]}=${x[1]}`)
    .reduce((p, c) => `${p}&${c}`);
  const src = `https://w.soundcloud.com/player/?${encodeURI(optionStr)}`;

  return (
    <iframe
      width="100%"
      height="100"
      scrolling="no"
      frameBorder="no"
      src={src}
    ></iframe>
  );
};

export default SoundCloud;
