import React from "react";
import styled from "styled-components";
import { terminaMixin, sourceCodeMixin, Button, TitleMixin } from "../style";

const Container = styled.section`
  grid-column: 3;
  grid-row: 9;
  margin: 65px 0 145px 0;

  @media (max-width: 575.98px) {
    margin: 41px 0 70px 0;
  }
`;

const Title = styled.h1`
  ${TitleMixin};

  @media (max-width: 575.98px) {
    margin-bottom: 40px;
  }
`;

const Place = styled.h2`
  ${terminaMixin};
  font-size: 36.89px;
  margin-top: 110px;

  @media (max-width: 575.98px) {
    font-size: 16.526667px;
    margin: 40px 0 37px 0;
  }
`;

const PriceList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 70px;

  @media (max-width: 575.98px) {
    margin-top: 0;
    margin-bottom: 41px;
  }

  li {
    ${sourceCodeMixin};
    font-size: 17px;
    text-transform: uppercase;
    display: inline-box;
    margin-right: 50px;

    @media (max-width: 575.98px) {
      font-size: 5.666667px;
      margin-right: 16.666667px;
      margin-top: 0;
    }

    .price {
      ${terminaMixin};
      font-size: 29.91px;
      margin: 0 10px 0 30px;

      @media (max-width: 575.98px) {
        font-size: 9.97px;
        margin: 0 10px 0 10px;
      }
    }
  }
`;

interface PriceProps {
  name: string;
  price: number;
}
const Price: React.FC<PriceProps> = props => (
  <li>
    {props.name}
    <span className="price">{props.price.toLocaleString()}</span>JPY
  </li>
);

const ShopList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  margin-top: 80px;
  @media (max-width: 575.98px) {
    margin-top: 0;
  }

  li {
    width: 300px;
    flex: 0 1 auto;
    margin: 0 17px 20px 0;

    @media (max-width: 575.98px) {
      width: 100px;
      margin: 0 6px 7px 0;
    }

    :nth-child(3n) {
      @media (max-width: 575.98px) {
        margin: 0 0 7px 0;
      }
    }
  }
`;

const ShopButton = styled(Button)`
  min-width: 300px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;

  &.disable {
    background-color: rgb(84, 82, 81);
    cursor: default;
    pointer-events: none;
  }

  @media (max-width: 575.98px) {
    min-width: 100px;
  }
`;

interface ShopProps {
  url: string;
}
const Shop: React.FC<ShopProps> = ({ children, url }) => (
  <li>
    <ShopButton as="a" className={`${url === "" ? "disable" : ""}`} href={url}>
      {children}
    </ShopButton>
  </li>
);

const prices = [{ name: "Event", price: 1500 }];

const shopLists = [
  {
    name: "Diverse Direct",
    url:
      "https://diverse.direct/compllege/compllege-x-diverse-system/dvsp-0235/"
  },
  {
    name: "TANO*C STORE",
    url:
      "https://www.tanocstore.net/shopdetail/000000002295/C97_W_2019/page1/recommend/"
  },
  { name: "MELONBOOKS", url: "" },
  { name: "TORANOANA", url: "" },
  { name: "AKIBAHOBBY", url: "" }
];

const Information: React.FC = () => {
  return (
    <Container id="information">
      <Title>Information</Title>
      <Place>
        COMIC MARKET 97
        <br />
        12.31(THU)West A-41ab
        <br />
        Diverse System & wavforme
      </Place>
      <PriceList>
        {prices.map(x => (
          <Price name={x.name} price={x.price} />
        ))}
      </PriceList>
      <ShopList>
        {shopLists.map(x => (
          <Shop url={x.url}>{x.name}</Shop>
        ))}
      </ShopList>
    </Container>
  );
};

export default Information;
