import React from "react";
import styled from "styled-components";

const scrollTo = (e: React.MouseEvent<HTMLElement>, href: string) => {
  e.preventDefault();

  const id = href.substring(1);
  const target = document.querySelector(`#${id}`);
  if (target !== null) {
    target.scrollIntoView({ behavior: "smooth" });
  }

  //history.pushState(null, null, `#${id}`);
};

interface AnchorLinkProps {
  className?: string;
  href: string;
}
const AnchorLink: React.FC<AnchorLinkProps> = ({
  className,
  href,
  children
}) => {
  return (
    <a className={className} href={href} onClick={e => scrollTo(e, href)}>
      {children}
    </a>
  );
};

export default styled(AnchorLink)`
  text-decoration: none;
  color: rgb(179, 179, 179);
  opacity: 1;

  :hover {
    opacity: 0.5;
  }
`;
