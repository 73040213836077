import React, { Fragment, useState, FormEvent, ChangeEvent } from "react";
import styled from "styled-components";
import {
  terminaMixin,
  kozukaMixin,
  Button,
  sourceCodeMixin,
  TitleMixin
} from "../style";
import axios from "axios";

const Container = styled.section`
  grid-column: 3;
  grid-row: 8;
  margin-top: 106px;
  margin-bottom: 120px;

  @media (max-width: 575.98px) {
    padding: 42px 0 50px 0;
    margin: 0 0 0 -24px;
    background-color: rgb(26, 26, 26);
    width: 360px;
  }
`;

const Background = styled.div`
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row: 8;
  background-color: rgb(26, 26, 26);
  @media (max-width: 575.98px) {
    display: none;
  }
`;

const Title = styled.h1`
  ${TitleMixin};
  margin: 0 0 76px 0;
  @media (max-width: 575.98px) {
    margin: 0 0 29px 0;
  }
`;

const Description = styled.p`
  ${kozukaMixin};
  font-size: 15px;
  width: 478px;
  flex: 0 1 auto;
  margin: 0;
  @media (max-width: 575.98px) {
    font-size: 7.333333px;
    width: 200px;
    margin: 0 auto 47px auto;
  }
`;

const Input = styled.input`
  width: 520px;
  height: 72px;
  ${sourceCodeMixin};
  font-size: 25px;
  background-color: rgb(255, 255, 255);
  border: none;
  text-align: center;
  @media (max-width: 575.98px) {
    width: 230px;
    height: 24px;
    margin: 0 auto 19px auto;
    font-size: 8.333333px;
  }

  &[disabled] {
    background-color: rgb(176, 176, 176);
  }
`;

const SendButton = styled(Button)`
  display: block;
  padding: 10px 0 8px;
  font-size: 14px;
  margin-top: 39px;
  cursor: pointer;
  align-self: flex-end;
  text-align: center;
  width: 217px;
  box-sizing: border-box;
  @media (max-width: 575.98px) {
    margin: 0 auto 18px auto;
  }
`;

const Body = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  @media (max-width: 575.98px) {
    display: block;
  }
`;

const Form = styled.form`
  flex: 0 1 auto;
  display: flex;
  flex-flow: column nowrap;
`;

const Notice = styled.div<{ error: string }>`
  ${kozukaMixin};
  font-size: 18px;
  color: rgb(179, 0, 0);
  text-align: right;
  margin-top: 36px;
  visibility: ${props => (props.error === "" ? "hidden" : "visible")};
  @media (max-width: 575.98px) {
    font-size: 7.333333px;
    margin: 0 auto;
  }
`;

const apiUrl = process.env.REACT_APP_API_URL;

const Contents: React.FC = () => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [hasSubmit, setSubmit] = useState(false);
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setSubmit(true);
    const { data } = await axios.post(`${apiUrl}/phant-prologue-dlc`, { code });
    if (data.error) {
      setError(data.error);
    } else {
      setError("");
      const downLoadLink = document.createElement("a");
      downLoadLink.href = data.url;
      downLoadLink.click();
    }
    setSubmit(false);
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value.substring(0, 10));
  };
  return (
    <Fragment>
      <Background />
      <Container id="contents">
        <Title>Contents</Title>
        <Body>
          <Description>
            本コンテンツは、パッケージ内に収録されているコードを使用して追加コンテンツをお楽しみ頂くことができます。
          </Description>
          <Form onSubmit={handleSubmit}>
            <Input value={code} onChange={handleChange} />
            <SendButton disabled={code.length < 10 || hasSubmit}>
              {hasSubmit ? "Sending" : "Apply"}
            </SendButton>
            <Notice error={error}>{error}</Notice>
          </Form>
        </Body>
      </Container>
    </Fragment>
  );
};

export default Contents;
