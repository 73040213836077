import React from "react";
import styled from "styled-components";
import MainVisual from "./MainVisual";
import GlobalMenu from "./GlobalMenu";
import Header from "./Header";
import "./App.css";
import Introduction from "./Introduction";
import Characters from "./Characters";
import TrackList from "./TrackList";
import Artists from "./Artists";
import Contents from "./Contents";
import Information from "./Information";
import Credits from "./Credits";
import Footer from "./Footer";
import PageTop from "./PageTop";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 243px 1064px 87px 1fr;

  @media (max-width: 575.98px) {
    display: block;
    grid-template-columns: auto;
    width: calc(100% - 13%);
    margin-left: 6.5%;
    margin-right: 6.5%;
  }
`;

const App: React.FC = () => {
  return (
    <Container id="top">
      <Header />
      <GlobalMenu />
      <MainVisual />
      <Introduction />
      <Characters />
      <TrackList />
      <Artists />
      <Information />
      <Contents />
      <Credits />
      <Footer />
      <PageTop />
    </Container>
  );
};

export default App;
