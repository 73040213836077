import styled, { css } from "styled-components";

export const terminaMixin = css`
  font-family: termina, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.46em;
`;

export const terminaMediumMixin = css`
  ${terminaMixin}
  font-weight: 500;
`;

export const terminaDemiMixin = css`
  ${terminaMixin}
  font-weight: 600;
`;

export const terminaBoldMixin = css`
  ${terminaMixin}
  font-weight: 700;
`;

export const kozukaMixin = css`
  font-family: kozuka-gothic-pr6n, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
`;
export const bodyMixin = css`
  ${kozukaMixin};
  text-indent: 1em;
`;

export const sourceCodeMixin = css`
  font-family: source-code-pro, monospace;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.06em;
`;

export const Button = styled.button`
  ${terminaBoldMixin};
  padding: 16px;
  background-color: #ffffff;
  display: inline-block;
  font-size: 10.5px;
  color: #000000;
  letter-spacing: 0.26em;
  text-transform: uppercase;
  border: none;
  text-decoration: none;

  @media (max-width: 575.98px) {
    width: calc(300px / 3);
    height: calc(55px / 3);
    font-size: calc(14px / 3);
    box-sizing: border-box;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &[disabled] {
    background-color: rgb(176, 176, 176);
    cursor: default;
  }
`;

export const RoundedButton = styled.button`
  ${terminaDemiMixin};
  display: block;
  width: 140px;
  height: 24px;
  text-align: center;
  box-sizing: border-box;
  letter-spacing: 0.26em;
  font-size: 8px;
  padding: 4px 0 2px 0;
  border: 1px solid rgb(179, 179, 179);
  border-radius: 2em;
  text-decoration: none;
  color: rgb(179, 179, 179);
`;

export const TitleMixin = css`
  ${terminaMixin}
  margin: 0;
  font-size: 16.95px;
  text-transform: uppercase;

  @media (max-width: 575.98px) {
    font-size: 8px;
    text-align: center;
    margin-bottom: 89px;
  }
`;
