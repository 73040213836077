import React from "react";
import styled from "styled-components";
import PlayButton from "../images/btn_play.svg";

interface TrailerProps {
  className?: string;
}

const Trailer: React.FC<TrailerProps> = ({ className }) => {
  return (
    <div className={className}>
      <iframe
        width="320"
        height="180"
        src="https://www.youtube.com/embed/4H6JVAep0X0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default styled(Trailer)`
  width: 320px;
  height: 180px;
  margin-top: 46px;
  background-color: rgb(179, 179, 179);
  color: rgb(0, 0, 0);
`;
