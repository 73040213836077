import React from "react";
import styled from "styled-components";

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 9.75px;
  line-height: 50px;

  li {
    color: rgb(102, 102, 102);
    display: inline-box;
    &.--active {
      color: rgb(0, 0, 0);
    }

    & + li {
      ::before {
        content: " / ";
        color: rgb(0, 0, 0);
      }
    }
  }
`;

interface EpisodesProps {
  className?: string;
}

const Episodes: React.FC<EpisodesProps> = ({ className }) => {
  return (
    <div className={className}>
      <List>
        <li className="--active">PHANT PROLOGUE EP 01</li>
        <li>HADALY IS GONE</li>
        <li>PHANT PROLOGUE EP 02</li>
      </List>
    </div>
  );
};

export default Episodes;
