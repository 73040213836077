import React, { Fragment, useState } from "react";
import styled from "styled-components";
import AnchorLink from "./AnchorLink";
import MediaQuery from "react-responsive";
import Episodes from "./Episodes";
import SpMenu from "../images/btn_sp_menu.svg";

const Container = styled.section<{ opened: boolean }>`
  display: flex;
  flex-direction: column;
  width: calc((100% - 1394px) / 2 + 243px);
  height: 100%;
  position: fixed;
  justify-content: center;

  @media (max-width: 575.98px) {
    width: 180px;
    display: block;
    position: fixed;
    top: 0;
    left: ${props => (props.opened ? "0px" : "-180px")};
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 100;

    transition: left 0.4s ease;
  }
`;

const MenuList = styled.ul`
  list-style: none;
  margin:0;
  padding: 0;

  @media (max-width: 575.98px) {
    padding: 93px 34px 17px 17px;
    border-bottom: 1px solid rgb(77, 77, 77);
  }

  li {
    font-size: 9px;
    margin-top 16px;
    @media (max-width: 575.98px) {
      font-size: 8.666667px
      letter-spacing: 0.46em;
    }

    a {
      margin-left :calc(100% - 243px);
      cursor: pointer;
      @media (max-width: 575.98px) {
        margin-left: 0;
      }
    }

    ::after {
      display: block;
      content: "";
      margin-top: 12px;

      @media (max-width: 575.98px) {
        width: 100%;
        border-bottom: 1px solid rgb(179, 179, 179);
      }
    }

    :nth-last-child(1) {
      ::after {
        border-bottom: none;
      }
    }
  }
`;

const SpEpisodes = styled(Episodes)`
  padding: 38px 34px 0px 17px;
  ul {
    line-height: initial;

    li {
      font-size: 7px;
      letter-spacing: 0.46em;
      margin-bottom: 24px;
      &.--active {
        color: rgb(176, 176, 176);
      }
      & + li {
        ::before {
          content: "";
          color: rgb(0, 0, 0);
        }
      }
    }
  }
`;

const MenuButton = styled.a`
  position: fixed;
  top: 16px;
  left: 17px;
  width: 20px;
  height: 20px;
  z-index: 101;
`;

const menus = [
  { id: "introduction", name: "INTRODUCTION" },
  { id: "character", name: "CHARACTER" },
  { id: "tracklist", name: "TRACKLIST" },
  { id: "contents", name: "CONTENTS" },
  { id: "information", name: "INFORMATION" },
  { id: "credits", name: "CREDITS" }
];

const GlobalMenu: React.FC = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Fragment>
      <Container opened={isOpen}>
        <MenuList>
          {menus.map(x => (
            <li>
              <AnchorLink href={`#${x.id}`}>{x.name}</AnchorLink>
            </li>
          ))}
        </MenuList>
        <MediaQuery query="(max-width:575.98px)">
          <SpEpisodes />
        </MediaQuery>
      </Container>
      <MediaQuery query="(max-width:575.98px)">
        <MenuButton onClick={_ => setOpen(!isOpen)}>
          <img src={SpMenu} />
        </MenuButton>
      </MediaQuery>
    </Fragment>
  );
};

export default GlobalMenu;
