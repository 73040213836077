import React from "react";
import styled from "styled-components";
import Trailer from "./Trailer";
import MainImage from "../images/img_top.jpg";
import SPImage from "../images/img_sp_top.jpg";
import { terminaMixin, Button, kozukaMixin } from "../style";
import MediaQuery from "react-responsive";

const Main = styled.section`
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row: 3;
  position: relative;
  margin-bottom: 280px;

  color: rgb(179, 179, 179);

  @media (max-width: 575.98px) {
    background-image: url(${SPImage});
    background-position-y: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 85px;
  }
`;

const Title = styled.h1`
  ${terminaMixin};
  font-size: 43.41px;
  white-space: nowrap;
  margin-top: -16px;

  @media (max-width: 575.98px) {
    font-size: 18.61px;
    white-space: inherit;
    margin-top: -12px;
    margin-left: -2px;
    width: 325px;
  }
`;

const Label = styled.p`
  ${terminaMixin};
  font-size: 30.47px;
  margin-top: 206px;

  @media (max-width: 575.98px) {
    margin-top: 305px;
    margin-left: -2px;
    width: 325px;
    font-size: 13.95px;
    white-space: nowrap;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  width: 894px;

  @media (max-width: 575.98px) {
    display: none;
  }
`;

const Illustration = styled.p`
  font-size: 7.5px;
  position: absolute;
  right: 0;
  bottom: 0;

  .ja {
    ${kozukaMixin};
    font-size: 7.5px;
    letter-spacing: 0.46em;
  }

  @media (max-width: 575.98px) {
    display: none;
  }
`;

const Buttons = styled.div`
  @media (max-width: 575.98px) {
    display: flex;
    margin-top: 50px;

    a {
      margin-right: 6px;
    }
  }
`;

const MainVisual: React.FC = () => {
  return (
    <Main>
      <Image src={MainImage} />
      <Title>PHANT PROLOGUE EP 01</Title>
      <Label>
        COMPLLEGE x <br />
        DIVERSE SYSTEM
        <br />
        <br />
        PHANT PROLOGUE
        <br />
        <br />
        EP 01 / 2019 Winter
        <br />
        EP 02 / 2020 Summer
      </Label>
      <Buttons>
        <MediaQuery query="(max-width:575.98px)">
          <Button
            as="a"
            target="_blank"
            href="https://www.youtube.com/embed/4H6JVAep0X0"
          >
            View Trailer
          </Button>
        </MediaQuery>
        <Button
          as="a"
          target="_blank"
          href="https://diverse.direct/compllege/compllege-x-diverse-system/dvsp-0235/"
        >
          Buy Diverse Direct
        </Button>
        <MediaQuery query="(min-width:576px)">
          <Trailer />
        </MediaQuery>
      </Buttons>
      <Illustration>
        ILLUSTRATION BY <span className="ja">東山エイト</span>
      </Illustration>
    </Main>
  );
};

export default MainVisual;
