import React from "react";
import styled from "styled-components";
import compllegeLogo from "../images/logo_compllege.svg";
import diverseLogo from "../images/logo_diverse.svg";
import { sourceCodeMixin } from "../style";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 575.98px) {
    justify-content: flex-end;
    width: 200px;
    flex-flow: row wrap;
  }

  .compllege {
    width: 30.959px;
    flex: 0 1 auto;
    margin-right: 30px;

    @media (max-width: 575.98px) {
      width: 19.333333px;
      margin-right: 20px;
    }
  }

  .diverse {
    width: 109.155px;
    flex: 0 1 auto;

    @media (max-width: 575.98px) {
      width: 67.894667px;
    }
  }

  .copyright {
    ${sourceCodeMixin};
    font-size: 7.5px;
    text-align: right;
    flex: 1 1 auto;
    @media (max-width: 575.98px) {
      margin-top: 10px;
      font-size: 4.333333px;
    }
  }
`;

const Label: React.FC = () => {
  return (
    <Container className="label">
      <img className="compllege" src={compllegeLogo} />
      <img className="diverse" src={diverseLogo} />
      <div className="copyright">
        DVSP-0235
        <br />© 2019 Compllege x Diverse System All Rights a Resverted.
      </div>
    </Container>
  );
};

export default Label;
