import React, { Fragment } from "react";
import styled from "styled-components";
import { terminaMixin, sourceCodeMixin, Button, TitleMixin } from "../style";
import BigJacketImage from "../images/jacket_big.png";
import JacketImage from "../images/img_jacket.jpg";
import SoundCloud from "./SoundCloud";

const Container = styled.section`
  grid-column: 3;
  grid-row: 6;

  display: grid;
  grid-template-columns: 1fr 467px;
  grid-template-rows: 116px 336px 22px;

  @media (max-width: 575.98px) {
    display: block;
    margin-left: 29px;
    margin-right: 29px;
    margin-bottom: 42px;
  }
`;

const Title = styled.h1`
  ${TitleMixin};
  grid-column: 1;
  grid-row: 1;
  @media (max-width: 575.98px) {
    margin-bottom: 23px;
  }
`;

const Jacket = styled.a`
  grid-column: 2;
  grid-row-start: 1;
  grid-row-end: 4;
  display: block;
  width: 467px;
  height: 473px;

  position: relative;

  img {
    width: 100%;
  }

  :hover {
    opacity: 0.75;
  }

  @media (max-width: 575.98px) {
    width: 100%;
    height: initial;
  }
`;

const DownloadButton = styled(Button)`
  background-color: #000000;
  color: #ffffff;
  font-size: 8.54px;
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 575.98px) {
    display: none;
  }
`;

const Track = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  position: relative;
`;

const Copyright = styled.p`
  ${sourceCodeMixin};
  font-size: 7.5px;

  grid-column: 1;
  grid-row: 3;
  margin: 0;

  @media (max-width: 575.98px) {
    font-size: 4.333333px;
    position: initial;
  }
`;

const List = styled.ol`
  list-style: none;
  padding: 0;
  grid-column: 1;
  grid-row: 2;
  margin: 0;
  @media (max-width: 575.98px) {
    margin-top: 20px;
  }
`;

const MusicTitle = styled(({ index, className, children, ...props }) => (
  <li {...props} className={className}>
    {children}
  </li>
))`
  font-size: 14px;
  margin-bottom: 24px;
  @media (max-width: 575.98px) {
    font-size: 6px;
    margin-bottom: 15px;
    letter-spacing: 0.460em;
  }
  ::before {
    ${terminaMixin};
    font-size: 9px;
    content: '${props => `0${props.index}`}';
    width: 20px;
    display: inline-block;
    margin-right: 40px;
    @media (max-width: 575.98px) {
      font-size: 6px;
      width: 14px;
      margin-right: 25px;
      letter-spacing: 0.460em;
    }
  }
`;

const SoundCloudWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 88px;
  margin-bottom: 77px;
  @media (max-width: 575.98px) {
    margin-top: 21px;
    margin-bottom: 0;
  }
`;

const tracks = [
  { title: "Signal" },
  { title: "Chapter1 : Necrophobia" },
  { title: "Chapter2 : Dependence" },
  { title: "Chapter3 : Obsession" },
  { title: "Survey Report -Hadaly is gone-" }
];

const TrackList: React.FC = () => {
  return (
    <Fragment>
      <Container id="tracklist">
        <Title>TrackList</Title>
        <Jacket href={BigJacketImage} target="_blank">
          <img src={JacketImage} />
          <DownloadButton as="div">Jacket Data Download</DownloadButton>
        </Jacket>
        <List>
          {tracks.map((x, i) => (
            <MusicTitle index={i}>{x.title}</MusicTitle>
          ))}
        </List>
        <Copyright>
          All Music Composed by Taishi
          <br />
          Tr.02 | Sample Track Poetry Reading by Cathy
        </Copyright>
        <SoundCloudWrapper>
          <SoundCloud />
        </SoundCloudWrapper>
      </Container>
    </Fragment>
  );
};

export default TrackList;
