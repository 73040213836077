import React, { Fragment } from "react";
import styled from "styled-components";
import taishiPhoto from "../images/img_profile_taishi.jpg";
import miyazakiPhoto from "../images/img_profile_miyazaki.png";
import { kozukaMixin, RoundedButton, Button } from "../style";
import MediaQuery from "react-responsive";

const Background = styled.div`
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row: 7;

  background-color: rgb(51, 51, 51);
`;

const Container = styled.section`
  grid-column: 3;
  grid-row: 7;

  margin-top: 80px;
  margin-bottom: 80px;

  @media (max-width: 575.98px) {
    margin-top: 42px;
    margin-bottom: 42px;
  }
`;

const Artist = styled.div`
  position: relative;
  min-height: 400px;

  display: grid;
  grid-template-columns: 400px 73px 1fr 73px 400px;

  @media (max-width: 575.98px) {
    grid-template-columns: 141px 10px 10px 10px 141px;
    min-height: initial;
  }

  .photo {
    width: 400px;
    grid-column: 1;
    grid-row-start:1;
    grid-row-end:6;
    @media (max-width: 575.98px) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start:1;
      grid-row-end:5;
      width: 151px;
      height: 151px;
    }

    img {
      width: 100%;
    }
  }

  .description {
    ${kozukaMixin};
    font-size: 15px;
    margin-bottom: 36px;
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row:4;
    @media (max-width: 575.98px) {
      margin-top:33px;
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row:5;
      font-size: 7.333333px;
      margin-bottom:0;
    }
  }

  .job {
    font-size: 11.7px;
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row:1;
    @media (max-width: 575.98px) {
      grid-column-start: 5;
      grid-column-end: 6;
      font-size: 5px;
      letter-spacing: 0.46em;
    }
  }

  .name {
    font-size: 23.33px;
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row:2;

    @media (max-width: 575.98px) {
      grid-column-start: 5;
      grid-column-end: 6;
      font-size: 9.97px;
      letter-spacing: 0.46em;
      margin-top:10px;
    }
  }
  .sns {
    list-style: none;
    padding: 0;
    margin-bottom: 36px;
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row:3;

    @media (max-width: 575.98px) {
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      grid-row:4;
      grid-column-start: 5;
      grid-column-end: 6;
    }

    li {
      display: inline-block;
      margin-right: 10px;
      @media (max-width: 575.98px) {
        margin-top: 7px;
      }
    }
  }

  & + & {
    margin-top: 131px;
    @media (max-width: 575.98px) {
      margin-top: 42px;
    }
  }

  &:nth-child(odd) {
    .sns {
      @media (max-width: 575.98px) {
        margin-top: 53px:
      }
    }
  }
  
  &:nth-child(even) {
    .job {
      grid-column-start:1;
      grid-column-end:4;
      @media (max-width: 575.98px) {
        grid-column-start:1;
        grid-column-end:3;
      }
    }
    .name {
      grid-column-start:1;
      grid-column-end:4;
      @media (max-width: 575.98px) {
        grid-column-start:1;
        grid-column-end:3;
      }
    }
    .description {
      grid-column-start:1;
      grid-column-end:4;
      @media (max-width: 575.98px) {
        grid-column-start:1;
        grid-column-end:3;
        margin-top: 21px;
        margin-bottom: 0px;
      }
    }
    .sns {
      grid-column-start:1;
      grid-column-end:4;
      @media (max-width: 575.98px) {
        grid-column-start:1;
        grid-column-end:3;
        margin-top:18px;
      }
    }
    .photo {
      grid-column:5;
      @media (max-width: 575.98px) {
        grid-column-start: 4;
        grid-column-end: 6;
        grid-row-end: 6;
      }
    }
  }
`;

// TODO Credit で使いまわす
const artists = [
  {
    photo: taishiPhoto,
    name: "Taishi",
    job: "SOUND DESIGN",
    sns: [
      { name: "twitter", url: "https://twitter.com/taishi_coad" },
      { name: "Sound Cloud", url: "https://soundcloud.com/compllege" },
      { name: "bandcmnp", url: "https://compllege.bandcamp.com/" }
    ],
    description:
      "2005年に音楽制作を中心に活動するユニット、Compllegeを立ち上げ、主催を務める。<br/> その他DJとしての活動を2007年から始め、現在は東京を拠点に様々なイベントに出演している。 商業での活動実績が多く、<br/> TVアニメ 「俺の妹がこんなに可愛いわけがない」にてEDテーマ「白いココロ」の作詞、作曲、一部編曲を担当した他、<br/> アニメ制作会社スタジオディーンの音楽レーベルである「cosmic record」にて多くの楽曲を発信している。 「cosmic record」では音楽ユニット「Electro.muster」の音楽プロデュースも手がけており<br/>エレクトロニック・ミュージックを中心に、多くのPCゲーム主題歌等を制作している。<br/>また、「蒼姫ラピス」や「IA」といったVOCALOIDのデモサウンドの制作や、<br/>CAPCOMのiOSアプリ「CROSS×BEATS」及び「オトレンジャー」、<br/>SEGAの「ハーフパイプ トーキョー」及び「maimai」等のゲームサウンドへの楽曲提供も行っており、<br/>音楽の活動範囲は多岐に渡っている。<br/><br/>現在、日本アニメ界が誇る声優の表現力と、本格アーティスト系楽曲のコラボレーションを、世界に発信するキャラクターソング・音楽CDプロジェクト「音楽少女」の楽曲を手がけている。"
  },
  {
    photo: miyazakiPhoto,
    name: "Shinichiro Miyazaki",
    job: "VISUAL DESIGN / SCRIPTER",
    sns: [{ name: "twitter", url: "https://twitter.com/rgrphs" }],
    description:
      "CGアーティスト。フリーランスで主にアニメ業界での仕事に従事する傍ら、Diverse Systemではグラフィックデザインやシナリオ執筆などで活動中。うどんと酢の物が好き、犬は最高。"
  }
];

const Artists: React.FC = () => {
  return (
    <Fragment>
      <Container>
        {artists.map((x, i) => (
          <Artist className={`artist artist_${i}`} key={i}>
            <div className="job">{x.job}</div>
            <div className="name">{x.name}</div>
            <div className="photo">
              <img src={x.photo} />
            </div>
            <ul className="sns">
              {x.sns.map(y => (
                <li>
                  <MediaQuery query="(max-width:575.98px)">
                    <Button as="a" target="_blank" href={y.url}>
                      {y.name}
                    </Button>
                  </MediaQuery>
                  <MediaQuery query="(min-width:576px)">
                    <RoundedButton as="a" target="_blank" href={y.url}>
                      {y.name}
                    </RoundedButton>
                  </MediaQuery>
                </li>
              ))}
            </ul>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: x.description }}
            />
          </Artist>
        ))}
      </Container>
      <Background />
    </Fragment>
  );
};

export default Artists;
