import React from "react";
import styled from "styled-components";
import pageTopSvg from "../images/btn_back2top.svg";
import AnchorLink from "./AnchorLink";

interface PageTopProps {
  className?: string;
}

const PageTop: React.FC<PageTopProps> = ({ className }) => {
  return (
    <a className={className}>
      <AnchorLink href="#top">
        <img src={pageTopSvg} alt="page top" />
      </AnchorLink>
    </a>
  );
};

export default styled(PageTop)`
  position: fixed;
  bottom: 24px;
  right: calc((100% - 1394px) / 2);

  width: 65px;
  height: 40px;
  cursor: pointer;

  @media (max-width: 575.98px) {
    width: 51.466667px;
    height: 32.233333px;
    right: 24px;
    bottom: 12px;
  }
`;
