import React, { Fragment } from "react";
import styled from "styled-components";
import Label from "./Label";
import Episodes from "./Episodes";
import MediaQuery from "react-responsive";

const HeaderLabel = styled.div`
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row: 2;
  margin: 12px 0 40px 0;

  @media (max-width: 575.98px) {
    grid-column-start: auto;
    grid-column-end: auto;
    grid-row: auto;
    display: flex;
    justify-content: flex-end;
  }
`;

const HeaderEpisodes = styled(Episodes)`
  background-color: #b3b3b3;
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row: 1;
  height: 50px;
  text-align: center;
`;

const Header: React.FC = () => {
  return (
    <Fragment>
      <MediaQuery query="(min-width:568px)">
        <HeaderEpisodes />
      </MediaQuery>
      <HeaderLabel>
        <Label />
      </HeaderLabel>
    </Fragment>
  );
};

export default Header;
