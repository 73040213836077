import React from "react";
import styled from "styled-components";
import { RoundedButton, TitleMixin, terminaBoldMixin, Button } from "../style";
import Label from "./Label";
import MediaQuery from "react-responsive";

const Container = styled.section`
  grid-column: 3;
  grid-row: 11;

  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  @media (max-width: 575.98px) {
    display: block;
    margin-left: -24px;
    background-color: rgb(26, 26, 26);
    padding: 4px 24px 24px 24px;
    width: 312px;
    margin-bottom: 0;
    text-transform: uppercase;
  }
`;

const ShareList = styled.ul`
  flex: 0 1 auto;
  list-style: none;
  padding: 0;
  margin-top: 32px;

  li {
    display: inline-box;
    margin-right: 10px;
  }

  @media (max-width: 575.98px) {
    margin-top: 0px;
    li {
      margin-right: 6px;
      :nth-child(3n) {
        margin-right: 0;
      }
    }
  }
`;

const FooterLabel = styled.div`
  width: 400px;
  flex: 0 1 auto;

  .label {
    flex-wrap: wrap;
    justify-content: start;
  }

  .compllege {
    width: 57.77px;
    margin-right: 50px;
  }

  .diverse {
    width: 203.689px;
  }

  .copyright {
    text-align: left;
    font-size: 10px;
    margin-top: 32px;
  }
`;

const Title = styled.h1`
  ${terminaBoldMixin};
  font-size: 4.666667px;
  text-align: center;
`;

const tweetString = "PHANT PROLOGUE EP01 | Compllege & Diverse System";
const url = "http://phant-prologue.diverse.jp/";

const lists = [
  {
    name: "twitter",
    url: `https://twitter.com/intent/tweet?text=${encodeURI(
      tweetString
    )}&url=${encodeURI(url)}`
  },
  {
    name: "Facebook",
    url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(url)}`
  },
  { name: "LINE", url: "https://social-plugins.line.me/lineit/share" }
];

const Footer: React.FC = () => {
  return (
    <Container>
      <MediaQuery query="(min-width:576px)">
        <FooterLabel>
          <Label />
        </FooterLabel>
      </MediaQuery>
      <MediaQuery query="(max-width:575.98px)">
        <Title>Share</Title>
      </MediaQuery>
      <MediaQuery query="(max-width:575.98px)">
        <ShareList>
          {lists.map(x => (
            <li>
              <Button as="a" target="_blank" href={x.url}>
                {x.name}
              </Button>
            </li>
          ))}
        </ShareList>
      </MediaQuery>
      <MediaQuery query="(min-width:576px)">
        <ShareList>
          {lists.map(x => (
            <li>
              <RoundedButton as="a" target="_blank" href={x.url}>
                {x.name}
              </RoundedButton>
            </li>
          ))}
        </ShareList>
      </MediaQuery>
    </Container>
  );
};

export default Footer;
