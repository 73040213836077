import React, { Fragment } from "react";
import styled from "styled-components";
import bg from "../images/img_introduction.jpg";
import bgSp from "../images/img_sp_introduction.jpg";
import { terminaMixin, bodyMixin, TitleMixin } from "../style";
import MediaQuery from "react-responsive";

const Container = styled.section`
  grid-column: 3;
  grid-row: 4;
  padding-top: 298px;
  padding-bottom: 320px;

  display: grid;
  grid-template-columns: 260px 1fr 724px;

  @media (max-width: 575.98px) {
    display: block;
    padding-top: 56px;
    padding-bottom: 131px;
    width: 360px;
    margin-left: -24px;

    background-image: url(${bgSp});
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
  }
`;

const Background = styled.div`
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row: 4;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;

  @media (max-width: 575.98px) {
  }
`;

const Title = styled.h1`
  ${TitleMixin};

  grid-column-start: 1;
  grid-column-end: 2;
`;

const Body = styled.div`
  grid-column-start: 3;
  grid-column-end: 4;

  p:nth-child(1) {
    margin-top: 0;
  }
  p {
    ${bodyMixin}
    font-size:15px;

    @media (max-width: 575.98px) {
      margin-left: 63px;
      margin-right: 63px;
      font-size: 11px;
    }
  }
`;

const Introduction: React.FC = () => {
  return (
    <Fragment>
      <Container id="introduction">
        <Title>Introduction</Title>
        <Body>
          <p>
            少し遠い先の未来、大西洋沖の海中にあるラダーという施設で死を克服する為の研究が行われていた。ベンサレムという巨大サーバーに仮想世界を作り、人間の意識を転送することで悠久的に生存し続ける事ができるという人類が長年夢見てきたものが完成間近となっていた。
          </p>
          <p>
            しかし、ある日の事。ラダーに居住していた研究員が行方不明になってしまったという知らせがハダリーという研究者から届いた。ハダリーが言うにはラダー内部でアンドロイドが暴走してしまったというので、ラダーを所有する企業アルカナ社は、行方不明になった研究員を捜索する為武装した調査員を派遣する事に決定した。
          </p>
          <p>
            調査の為ラダー内部に潜入したジョンソンは、施設の中で昏睡状態のまま眠り続ける少女に出会う。
          </p>
          <p>
            作曲家Taishiが主宰を務める音楽サークルCompllegeがリリースするPhantシリーズの原点を描いた作品がDiverse
            Systemの協力の元描かれる。
          </p>
        </Body>
      </Container>
      <MediaQuery query="(min-width:576px)">
        <Background />
      </MediaQuery>
    </Fragment>
  );
};

export default Introduction;
