import React from "react";
import styled from "styled-components";
import {
  terminaMixin,
  sourceCodeMixin,
  Button,
  terminaMediumMixin,
  kozukaMixin,
  TitleMixin
} from "../style";

const Container = styled.section`
  grid-column: 3;
  grid-row: 10;
  margin: 0 0 130px 0;

  @media (max-width: 575.98px) {
    margin: 20px 0 27px 0;
  }
`;

const Title = styled.h1`
  ${TitleMixin};
  @media (max-width: 575.98px) {
    margin-bottom: 46px;
  }
`;

const CreditList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 100px;

  display: grid;
  grid-template-columns: 500px 1fr 422px;

  @media (max-width: 575.98px) {
    margin-top: 0px;
    display: block;
  }

  li {
    margin-bottom: 50px;
    @media (max-width: 575.98px) {
      margin-bottom: 16.666667px;
    }
    h2 {
      ${terminaMixin};
      font-size: 11px;
      text-transform: uppercase;
      margin: 0 0 40px 0;
      @media (max-width: 575.98px) {
        text-align: center;
        font-size: 3.666667px;
        margin-bottom: 7px;
      }
    }

    .staff {
      ${terminaMediumMixin};
      font-size: 16px;
      margin-bottom: 20px;
      span + span {
        ::before {
          content: " / ";
        }
      }
      a {
        text-decoration: none;
        color: rgb(179, 179, 179);
      }

      &.--ja {
        ${kozukaMixin};
        letter-spacing: 0.46em;
      }

      @media (max-width: 575.98px) {
        text-align: center;
        font-size: 8.333333px;
        margin-bottom: 0px;
      }
    }

    .twitter {
      ${terminaMixin};
      font-size: 11px;
      span + span {
        ::before {
          content: " / ";
          color: rgb(179, 38, 0);
        }
      }
      a {
        text-decoration: none;
        color: rgb(179, 38, 0);
      }

      @media (max-width: 575.98px) {
        display: none;
      }
    }
  }

  li:nth-child(odd) {
    grid-column: 1;
  }
  li:nth-child(even) {
    grid-column: 3;
  }
`;

interface CreditProps {
  job: string;
  staff: { name: string; twitter: string }[];
  lang: string;
}
const Credit: React.FC<CreditProps> = ({ job, staff, lang }) => {
  return (
    <li>
      <h2>{job}</h2>
      <div className={`staff ${lang === "ja" ? "--ja" : ""}`}>
        {staff.map(x => (
          <span>
            <a target="_blank" href={`https://twitter.com/${x.twitter}`}>
              {x.name}
            </a>
          </span>
        ))}
      </div>
      <div className="twitter">
        {staff.map(x => (
          <span>
            <a target="_blank" href={`https://twitter.com/${x.twitter}`}>
              @{x.twitter}
            </a>
          </span>
        ))}
      </div>
    </li>
  );
};

const Notice = styled.div`
  ${kozukaMixin};
  font-size: 14px;
  @media (max-width: 575.98px) {
    font-size: 4.666667px;
    text-align: center;
  }
`;

const credits = [
  {
    job: "DIRECTOR",
    staff: [
      { name: "Taishi", twitter: "taishi_coad" },
      { name: "Shinichiro Miyazaki", twitter: "rgrphs" }
    ],
    lang: "en"
  },
  {
    job: "PRODUCER",
    staff: [{ name: "YsK439", twitter: "YsK439" }],
    lang: "en"
  },
  {
    job: "SOUND",
    staff: [{ name: "Taishi", twitter: "taishi_coad" }],
    lang: "en"
  },
  {
    job: "SCENARIO WRITER",
    staff: [{ name: "Shinichiro Miyazaki", twitter: "rgrphs" }],
    lang: "en"
  },
  {
    job: "POETRY READING",
    staff: [{ name: "Cathy", twitter: "Kyashiko" }],
    lang: "en"
  },
  {
    job: "ILLUSTRATION",
    staff: [{ name: "東山エイト", twitter: "eight_tohyama" }],
    lang: "ja"
  },
  {
    job: "GRAPHIC DESIGN",
    staff: [{ name: "Shinichiro Miyazaki", twitter: "rgrphs" }],
    lang: "en"
  },
  {
    job: "FRONTEND ENGNIEER",
    staff: [{ name: "cellfusion", twitter: "cellfusion" }],
    lang: "en"
  },
  {
    job: "SPECIAL THANKS",
    staff: [{ name: "SoU", twitter: "sou_wav" }],
    lang: "en"
  }
];

const Credits: React.FC = () => {
  return (
    <Container id="credits">
      <Title>Credits</Title>
      <CreditList>
        {credits.map(x => (
          <Credit job={x.job} staff={x.staff} lang={x.lang} />
        ))}
      </CreditList>
      <Notice>
        この物語はフィクションです。
        <br />{" "}
        登場する人物・団体・名称等は架空であり、実在のものとは関係ありません。
      </Notice>
    </Container>
  );
};

export default Credits;
